* {
	margin: 0;
	padding: 0;
	border: 0;
	vertical-align: baseline;
	background: transparent;
	font-weight: normal;
	font-style: normal;
	text-decoration: none;
	outline: none;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}
html {
	scroll-behaviour: smooth;
}
@font-face {
	font-family: "bold";
	src: local("Gordita"), url('https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/fonts/bold.ttf') format("truetype");
}

@font-face {
	font-family: "medium";
	/* font-weight: 900; */
	src: local("Gordita"), url('https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/fonts/medium.ttf') format("truetype");
}

@font-face {
	font-family: "regular";
	/* font-weight: 900; */
	src: local("Gordita"), url('https://oneruppee-react-static-media.s3.ap-southeast-1.amazonaws.com/fonts/Gordita.ttf') format("truetype");
}
ol,
ul {
	list-style: none;
}
blockquote {
	quotes: none;
}
blockquote:before,
blockquote:after {
	content: "";
	content: none;
}
del {
	text-decoration: line-through;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type="number"] {
	-moz-appearance: textfield;
}
body {
	position: relative;
}

header,
section,
article,
aside,
nav,
footer,
address {
	display: block;
}

input,
textarea,
select {
	font-family: "open_sansregular", Arial, Helvetica, sans-serif;
}

a {
	text-decoration: none;
}
.wrapper {
	width: 90%;
	margin: 0 auto;
	max-width: 1420px;
}

.img-class {
	width: 100%;
	height: 100%;
	display: inline-block;
}

/*  */
/* .slick-list {
	overflow: hidden;
	width: 84%;
}
.slick-track {
	display: flex;
}
.slick-slide {
	width: 65px !important;
	height: 65px !important;
	margin-right: 8px;
	border-radius: 5px;
	overflow: hidden !important;
} */
/* .slick-active { */
	/* transform: scale(1) */
/* } */
/* .slick-dots {
	display: none !important;
}
.slick-slider {
	display: flex;
	align-items: center;
	justify-content: space-between;
} */

/* @media all and (max-width: 1080px) {
	.slick-slide {
		width: 50px !important;
		height: 50px !important;
	}
}
@media all and (max-width: 980px) {
	.slick-slide {
		width: 45px !important;
		height: 45px !important;
	}
} */
